export const HASURA_HOST = window.location.host === 'chainborn.xyz' ? 'chainborn.xyz/api' : 'ghost.chainborn.xyz/api'
//export const HASURA_HOST = 'chainborn.xyz/api'
export const PRERENDER_BASE_URI = window.location.host === 'chainborn.xyz' ? 'https://prerender.chainborn.xyz' : 'https://prerender-ghost.chainborn.xyz'
export const IPFS_BASE_URI = 'https://chainborn.infura-ipfs.io/ipfs'
export const DISCORD_INVITE = 'https://discord.gg/mC3cKsAFws'
export const DATE_FORMAT = 'yyyy-MM-dd'
export const TIME_FORMAT = 'yyyy-MM-dd HH:mm'
export const CHAIN_MINISTRY_TOKEN_ADDRESS = 'KT1EGtg6pYH3KYrNccQ7NXmQcdG4f1qBEJgX'
export const CHAIN_MINISTRY_PLAYER_ADDRESS = 'tz1KjVCLM419BwWZWkT1As6KLpcnJhezYrnv'
export const POTIONBELT_MAX_POTIONS = 3
export const ITEM_CATEGORIES = ["armour", "helmet", "weapon", "potion", "skin", "hero", "special"] 
export const BATTLE_MODE = {
  LOOT: 'loot',
  BOTH: 'both',
}
export const CB_ALTME_MEMBERSHIP_CARD_TEZID_ID = 'urn:uuid:0e57d9-0591-4444-95c0-9b363453578'
export const CB_ALTME_MEMBERSHIP_CARD_LINK = 'https://talao.co/altme-identity'
export const MESSAGE_CONFIRMATION_SETTINGS = 'I want to update my settings'
export const SESSION_ERROR_MESSAGE = 'No valid session found'
export const LAYOUT_HEADER_HEIGHT = 72
export const LAYOUT_MAX_WIDTH = 1920
export const LAYOUT_SIDEBAR_WIDTH_DEFAULT = 256
export const COLLECTION_IMAGE_RATIO = {
//  'KT1EGtg6pYH3KYrNccQ7NXmQcdG4f1qBEJgX': {
//    width: 1,
//    height: 1.15
//  }
}
export const HERO_IMAGE_RATIO = {}
export const COLLECTION_IMAGE_SIZE = {
}
export const HERO_IMG_SIZE = {}
export const DEFAULT_HERO_IMG_SIZE = 0.4
export const COLLECTION_IMAGE_TRANSFORMS = {
  'KT1EHBbkWMJ4n2KHuBzXMdKttwRnY7eDhmfV': {
    transform: 'translateY(4%) scale(0.9, 1.04)'
  },
  'KT1EGtg6pYH3KYrNccQ7NXmQcdG4f1qBEJgX': {
    transform: 'translateY(4%) scale(0.9, 1.04)'
  },
  'KT1BqfEQFrfx3h2wWQo7gTM1SE6FpH1Y5pqK': {
    transform: 'translate(2%, 30%) scale(1.5)'
  }
}
export const HERO_IMAGE_TRANSFORM = {}
export const HEROES_TO_IGNORE = [
  'KT1EGtg6pYH3KYrNccQ7NXmQcdG4f1qBEJgX__0',
  'KT1EGtg6pYH3KYrNccQ7NXmQcdG4f1qBEJgX__1',
  'KT1EGtg6pYH3KYrNccQ7NXmQcdG4f1qBEJgX__2',
]
